@skeleton-prefix-cls: ~'@{ant-prefix}-skeleton';
.@{card-prefix-cls} {
  &-rtl {
    .@{skeleton-prefix-cls}-header {
      padding: 0 0 0 @card-head-padding;
    }
  }
}

nz-card {
  display: block;
}

nz-card-meta {
  display: block;
}

nz-card-loading {
  display: block;
}
