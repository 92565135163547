@import "../../node_modules/antd-css-utilities/utility.min.css";

.fs-secondary {
  font-size: 85%;
}

.list-unstyled {
  list-style: none;
  padding-left: 0;
}

.text-no-wrap {
  text-wrap: nowrap;
}
