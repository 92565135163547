.ant-menu-submenu.ant-menu-submenu-placement-bottom {
  position: relative;
  top: 6px;
}

.ant-menu-submenu.ant-menu-submenu-placement-right {
  position: relative;
  left: 4px;

  &.ant-menu-submenu-rtl {
    right: 4px;
    left: auto;
  }
}

.ant-menu-submenu.ant-menu-submenu-placement-left {
  position: relative;
  right: 4px;

  &.ant-menu-submenu-rtl {
    right: auto;
    left: 4px;
  }
}
