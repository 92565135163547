@import '../../style/themes/index';
@import '../../style/mixins/index';

@qrcode-prefix-cls: ~'@{ant-prefix}-qrcode';

.@{qrcode-prefix-cls} {
  position: relative;
  display: inline-block;
  padding: @padding-sm;

  &>canvas {
    display: block;
  }
}

.@{qrcode-prefix-cls}-border {
  border: @border-width-base @border-style-base @border-color-split;
  border-radius: @border-radius-base;
}

.@{qrcode-prefix-cls}-mask {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 10;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  text-align: center;
  background-color: fade(@white, 96%);
}

.@{qrcode-prefix-cls}-expired {
  color: fade(@black, 85%);
}
