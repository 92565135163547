@import './themes/@{root-entry-name}.less';

// cdk overlay
.cdk-overlay-container,
.cdk-global-overlay-wrapper {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
}

.cdk-overlay-container {
  position: fixed;
  z-index: 1000;
}

.cdk-overlay-container:empty {
  display: none;
}

.cdk-global-overlay-wrapper {
  position: absolute;
  z-index: 1000;
  display: flex;
}

.cdk-overlay-pane {
  position: absolute;
  z-index: 1000;
  display: flex;
  box-sizing: border-box;
  max-width: 100%;
  max-height: 100%;
  pointer-events: auto;
}

.cdk-overlay-backdrop {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1000;
  opacity: 0;
  transition: opacity 400ms cubic-bezier(0.25, 0.8, 0.25, 1);
  pointer-events: auto;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

.cdk-overlay-backdrop.cdk-overlay-backdrop-showing {
  opacity: 1;
}

.cdk-high-contrast-active .cdk-overlay-backdrop.cdk-overlay-backdrop-showing {
  opacity: 0.6;
}

.cdk-overlay-dark-backdrop {
  background: rgba(0, 0, 0, 0.32);
}

.cdk-overlay-transparent-backdrop {
  visibility: hidden;
  opacity: 1;
  transition: visibility 1ms linear, opacity 1ms linear;
}

.cdk-overlay-transparent-backdrop.cdk-overlay-backdrop-showing {
  visibility: visible;
  opacity: 0;
}

.cdk-overlay-backdrop-noop-animation {
  transition: none;
}

.cdk-overlay-connected-position-bounding-box {
  position: absolute;
  z-index: 1000;
  display: flex;
  flex-direction: column;
  min-width: 1px;
  min-height: 1px;
}

.cdk-global-scrollblock {
  position: fixed;
  width: 100%;
  overflow-y: scroll;
}

// cdk a11y
.cdk-visually-hidden {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  overflow: hidden;
  border: 0;
  outline: 0;
  /* stylelint-disable property-no-vendor-prefix */
  -webkit-appearance: none;
  -moz-appearance: none;
  /* stylelint-enable property-no-vendor-prefix */
}

.nz-overlay-transparent-backdrop {
  &,
  &.cdk-overlay-backdrop-showing {
    opacity: 0;
  }
}

.nz-animate-disabled {
  // badge
  &.ant-scroll-number-only {
    transition: none;
    animation: none;
  }

  // drawer
  &.ant-drawer {
    &.ant-drawer-open .ant-drawer-mask {
      transition: none;
      animation: none;
    }

    & > * {
      transition: none;
    }
  }

  // modal
  .ant-modal-mask,
  .ant-modal {
    transition: none;
    animation: none;

    &.zoom-enter,
    &.zoom-leave,
    &.zoom-enter-active,
    &.zoom-leave-active {
      transition: none;
      animation: none;
    }
  }

  // menu
  &.ant-menu {
    transition: none;

    .ant-menu-item,
    .ant-menu-submenu-title {
      transition: none;
    }

    .ant-menu-item .anticon,
    .ant-menu-submenu-title .anticon {
      transition: none;

      & + span {
        transition: none;
      }
    }
  }

  // tabs
  &.ant-tabs {
    .ant-tabs-top-content.ant-tabs-content-animated,
    .ant-tabs-bottom-content.ant-tabs-content-animated,
    .ant-tabs-top-content > .ant-tabs-tabpane,
    .ant-tabs-bottom-content > .ant-tabs-tabpane,
    &.ant-tabs-left .ant-tabs-ink-bar-animated,
    &.ant-tabs-right .ant-tabs-ink-bar-animated,
    &.ant-tabs-top .ant-tabs-ink-bar-animated,
    &.ant-tabs-bottom .ant-tabs-ink-bar-animated {
      transition: none;
    }
  }

  // collapse
  &.ant-collapse > .ant-collapse-item > .ant-collapse-header .ant-collapse-arrow svg {
    transition: none;
  }
}
