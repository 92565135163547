// The following patch applies only to input-number-legacy.
.@{ant-prefix}-input-number {
  &-affix-wrapper {
    > nz-input-number.@{ant-prefix}-input-number {
      width: 100%;
      border: none;
      outline: none;

      &.@{ant-prefix}-input-number-focused {
        box-shadow: none !important;
      }
    }
  }

  &&-has-feedback {
    .@{ant-prefix}-input-number-handler-wrap {
      z-index: 2;
    }
  }
}
