.@{ant-prefix}-otp {
  display: inline-flex;
  flex-wrap: nowrap;
  align-items: center;
  column-gap: 8px;

  &-input {
    text-align: center;
    padding-inline: 4px;
  }
}
