.ant-tree.ant-select-tree.ant-tree-show-line nz-tree-node[builtin]:not(:last-child) > li::before {
  position: absolute;
  left: 12px;
  width: 1px;
  height: calc(100% - 16px);
  margin: 26px 0;
  border-left: 1px solid #d9d9d9;
  content: ' ';
}

.ant-select-dropdown.ant-select-tree-dropdown {
  position: relative;
  top: 100%;
  left: 0;
  width: 100%;
  margin-top: 4px;
  margin-bottom: 4px;
  overflow: auto;
}