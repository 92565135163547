nz-carousel {
  position: relative;
  display: block;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.slick-dots {
  display: block;
}

.slick-track {
  opacity: 1;
}
