.ant-select-dropdown-hidden {
  display: none;
}

.ant-select-dropdown-content-wrapper {
  max-height: 256px;
  overflow-y: auto;
  overflow-anchor: none;
}

.ant-select-dropdown-content {
  display: flex;
  flex-direction: column;
}
