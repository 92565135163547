.ant-page-header-back-button {
  display: inline-block;
  padding: 0;
  line-height: inherit;
  background: transparent;
  border: 0;
}

nz-page-header,
nz-page-header-content,
nz-page-header-footer {
  display: block;
}
