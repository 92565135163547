
body.recaptcha-badge-hide {
  .grecaptcha-badge {
    display: none !important;
  }
}

.response-error-notification {
  .ant-collapse-header {
    padding-left: 0 !important;
  }
  .ant-collapse-content-box {
    padding-left: 0 !important;
    padding-top: 0 !important;
  }
}

.app-header {
  .ant-page-header {
    padding: 12px 25px;
  }
}

.ant-page-header-heading-left {
  align-items: baseline;
}

nz-list-item.with-icon {
  nz-list-item-meta-avatar {
    align-self: center;
    font-size: 20px;
  }
}

.hljs-line-numbers {
  .hljs-ln-line.hljs-ln-numbers {
    background-color: @border-color-base;
    color: @text-color-secondary;
    text-align: center;
    padding: 0 7px;
    font-size: 11px;
  }
  .hljs-ln-line.hljs-ln-code {
    padding-left: 5px;
  }
}

.inline-date-picker {
  .ant-picker-panel-container {
    box-shadow: none !important;
  }
  .ant-picker-dropdown-range {
    padding: 0;
  }
}

html.cdk-global-scrollblock {
  overflow: hidden !important;
}

.ag-action-column {
  .ag-header-cell-label {
    display: none;
  }
  .ag-cell-label-container {
    justify-content: center;
  }
}

body {
  overflow: auto;
  --ag-font-family: @font-family;
  --ag-active-color: @primary-color;
  --ag-spacing: 7px;
  --ag-border-radius: @table-border-radius-base;
  --ag-wrapper-border-radius: @table-border-radius-base;
  --ag-border-color: @table-border-color;
}

